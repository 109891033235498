(function () {
  'use strict';

  /* @ngdoc object
   * @name settings
   * @description
   *
   */
  angular
    .module('settings', [
      'ui.router',
      'settings.users',
      'settings.events'
    ]);
}());
